<template>
	<div class="display-flex" :class="_class">
		<button
			v-for="(nav, index) in subNavigation"
			:key="index"
			class="c-navigation__button button padding-x-32 margin-x-4"
			:class="{ active: currentTab === nav.route }"
			@click="selectTab(nav)"
		>
			{{ nav.name }}
		</button>
	</div>
</template>

<script>
import { onBeforeUnmount, onMounted, ref } from 'vue';
export default {
	name: 'ChampionshipNavigationNav',
	props: {
		_class: {
			type: String,
			required: false,
		},
		champInfoExists: {
			type: Boolean,
			required: true,
		},
		externalTicketLink: {
			type: String,
			default: '',
		},
		ticketHospitalityLink: {
			type: String,
			default: '',
		},
	},
	emits: ['selected-tab'],
	data() {
		return {
			currentTab: null,
			subNavigation: [],
			isMobile: false,
		};
	},
	methods: {
		selectTab(tab) {
			if (tab.name == 'Ticket Info' && !this.champInfoExists) {
				return (window.location = this.externalTicketLink);
			}
			if (tab.name == 'Tickets & Hospitality') {
				window.open(this.ticketHospitalityLink, '_blank');
				return;
			}
			this.currentTab = tab.route;
			this.$router.push({
				name: tab.route,
				params: {
					slug: this.$route.params.slug,
				},
			});
		},
		updateNavigation() {
			this.subNavigation = [];

			if (this.externalTicketLink || this.champInfoExists) {
				this.subNavigation.push({
					name: 'Ticket Info',
					route: 'Championship',
				});
			}

			if (this.ticketHospitalityLink && !this.isMobile) {
				this.subNavigation.push({
					name: 'Tickets & Hospitality',
					route: 'TicketsAndHospitality',
					external: true,
				});
			}

			if (this.champInfoExists) {
				this.subNavigation.push({
					name: 'Champ Info',
					route: 'ChampionshipChampInfo',
				});
			}

			if (this.$route.name) {
				this.currentTab = this.$route.name;
			}
		},
	},
	created() {
		const mediaQuery = window.matchMedia('(max-width: 600px)');
		this.isMobile = mediaQuery.matches;

		mediaQuery.addEventListener('change', e => {
			this.isMobile = e.matches;
			this.updateNavigation();
		});

		this.updateNavigation();
	},
};
</script>

<style lang="scss" scoped>
.c-navigation {
	&__button {
		border: 2px solid white;
		color: white;
		background-color: transparent;
		&.active {
			background-color: white;
			color: var(--color-primary);

			// Hide on mobile (not a standard breakpoint, but standard doesn't work while maintaining styles)
			@media screen and(max-width: 600px) {
				display: none;
			}
		}
	}
}
</style>
