<template functional>
	<div
		class="utility-flex-center"
		:style="{
			height: `${parseInt(props.verticalSpace) +
				parseInt(props.height)}px`,
		}"
	>
		<div
			class="loading-indicator bg-color-surface-alt margin-l-auto margin-r-auto position-relative"
			:style="{
				width: `${parseInt(props.width)}px`,
				height: `${parseInt(props.height)}px`,
			}"
		>
			<div class="position-absolute bg-color-primary"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'LoadingIndicator',

	props: {
		width: {
			type: [Number, String],
			default: 120,
		},
		height: {
			type: [Number, String],
			default: 4,
		},
		verticalSpace: {
			type: [Number, String],
			default: 0,
		},
	},
};
</script>

<style lang="scss" scoped>
.loading-indicator {
	> div {
		animation: load 1s infinite ease-in-out;
		height: 100%;
		width: 0;
	}
}

@keyframes load {
	0% {
		right: 100%;
		width: 0%;
	}
	50% {
		right: 0%;
		width: 100%;
	}
	100% {
		right: 0%;
		width: 0%;
	}
}
</style>
