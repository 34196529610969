<template>
	<div
		class="component c-future-site position-relative shadow-level-1"
		v-if="site"
		:class="[
			{ 'c-future-site--final': final },
			{ 'c-future-site--prelim': preliminary },
		]"
	>
		<div
			class="c-future-site__media aspect-ratio-1-1 bg-color-black overflow-hidden position-relative"
		>
			<picture
				class="c-future-site__image utility-position-cover object-fit-cover"
				v-if="event_image && event_image[0].url != ''"
			>
				<source
					:srcset="$root.get_image_resizer_url(`${$root.proxy}${image.url}`)"
					:alt="image.alt"
					:media="`(min-width: ${image.breakpoint}px)`"
					v-for="(image, index) in event_image"
					:key="index"
				/>
				<img
					class="object-fit-cover height-100 width-100"
					v-lazy="$root.get_image_resizer_url(`${$root.proxy}${event_image[0].url}`)"
					:alt="event_image[0].alt"
				/>
			</picture>
			<img
				class="utility-position-cover object-fit-cover height-100 width-100"
				src="@/assets/placeholder.jpg"
				alt=""
				v-else
			/>
		</div>
		<div
			class="c-future-site__details font-size-14 position-absolute bottom-0 left-0 padding-24 text-white width-100 z-index-2
				bp-768:font-size-18
			"
			:class="{
				'display-flex flex-direction-column justify-content-flex-end bp-768:flex-direction-row bp-768:align-items-flex-end bp-768:justify-content-flex-start bp-1024:padding-32': final,
			}"
		>
			<div
				class="c-future-site__info bp-768:margin-r-auto bp-768:padding-r-24"
			>
				<div
					class="c-national__championship__title header-font font-size-30 line-height-150"
					:class="[{ 'bp-768:font-size-48': final }]"
				>
					{{ site.city }}, {{ site.state }}
				</div>
				<div
					class="c-future-site__locale font-size-14 text-shadow"
					:class="{ '': final }"
				>
					<span
						class="c-future-site__locale-location font-weight-700"
					>
						{{ site.title }}
					</span>
					<span class="margin-x-4 text-muted-on-dark">
						|
					</span>
					<span
						class="c-future-site__locale-stadium text-muted-on-dark"
					>
						{{ site.facility }}
					</span>
				</div>
			</div>
			<ul
				class="c-future-site__options margin-t-12 reset-list"
				v-if="site.link && site.link.link != ''"
			>
				<li class="c-future-site__option">
					<a
						class="c-future-site__option-btn button --on-dark-alt"
						:href="site.link.link"
						target="_blank"
						>{{ site.link.title }}</a
					>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import { sort_object_reversed } from '@/helpers';

export default {
	name: 'FutureSiteCard',

	props: {
		preliminary: {
			type: Boolean,
			default: true,
		},
		final: {
			type: Boolean,
			default: false,
		},
		site: {
			required: true,
			type: Object,
			default: null,
		},
	},

	computed: {
		event_image() {
			return sort_object_reversed(this.site.image, true);
		},
	},
};
</script>

<style lang="scss" scoped>
.c-future-site {
	&__media {
		&::before {
			content: '';
			background: linear-gradient(
				180deg,
				rgba(0, 0, 34, 0) 0%,
				rgba(0, 0, 34, 0.8) 100%
			);
			height: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 1;
		}
	}
	&__info {
		flex: 1 1 auto;
	}
	&__options {
		flex: 0 0 auto;
	}
}

@media screen and (min-width: 540px) {
	.c-future-site--final .c-future-site__media {
		padding-bottom: 56.25% !important;
	}
}

@media screen and (min-width: 1280px) {
	.c-future-site--final .c-future-site__media {
		height: 500px;
		padding-bottom: 0 !important;
	}
}
</style>
