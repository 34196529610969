<template>
	<div
		class="component c-event bg-color-surface position-relative height-100 shadow-level-1"
		:class="[
			{ '--no-teams': !has_schools },
			{ 'is-open': other_links_active || ticket_options_active },
		]"
		v-if="event"
	>
		<div
			class="top-0 left-0 height-100 width-100"
			:class="[
				{
					'bp-540:display-flex justify-content-center align-items-center': !has_schools,
				},
				{
					'position-static':
						other_links_active || ticket_options_active,
				},
			]"
		>
			<div
				class="c-event__info display-flex flex-direction-column justify-content-center overflow-hidden position-relative text-align-center height-100
					bp-1024:justify-content-flex-start"
				:class="{ 'height-100': has_schools }"
			>
				<div class="flex-grow-0 flex-shrink-0 mobile-aspect-ratio-5-2">
					<img
						class="bg-color-surface-alt object-fit-cover
							bp-540:width-100 bp-540:height-100 bp-540:object-fit-contain"
						v-if="event.image && event.image.url"
						:src="$root.get_image_resizer_url(`${$root.proxy}${event.image.url}`)"
						:alt="event.image.alt"
					/>
				</div>
				<div
					class="padding-y-32 padding-x-16 bp-1024:padding-x-32 display-flex flex-direction-column align-items-center height-100"
				>
					<div
						class="c-event__title font-size-24 header-font text-safe-on-light text-transform-uppercase"
					>
						<chevron-right-icon
							class="text-on-primary transition"
							size=".9x"
						></chevron-right-icon>
						<span
							v-html="
								event.title.replaceAll(
									/(?:\r\n|\r|\n)/g,
									'<br />',
								)
							"
						></span>
					</div>
					<div
						class="c-event__date font-size-14 font-weight-700 margin-t-8 text-brand text-transform-uppercase"
						v-show="event.start_date"
					>
						<span
							class="c-event__date-start"
							v-if="event.start_date"
							>{{ event.start_date | luxon }}</span
						>
						<span
							class="margin-x-4"
							v-if="
								event.end_date &&
									event.end_date !== event.start_date
							"
							>-</span
						>
						<span
							class="c-event__date-end"
							v-if="
								event.end_date &&
									event.end_date !== event.start_date
							"
							>{{ event.end_date | luxon }}</span
						>
					</div>
					<div
						class="c-event__locale font-size-14 margin-t-8 margin-b-24"
						v-show="event.city || event.state || event.facility"
					>
						<span
							class="c-event__locale-location font-weight-700 text-safe-on-light"
						>
							{{ event.city }}
							{{ event.city && event.state ? ', ' : '' }}
							{{ event.state }}
						</span>
						<span
							class="text-muted margin-x-4"
							v-if="event.facility && event.facility !== ''"
							>|</span
						>
						<span class="c-event__locale-stadium text-muted">{{
							event.facility
						}}</span>
					</div>
					<ul
						class="c-event__teams margin-b-24 reset-list utility-flex-center flex-wrap-wrap"
						v-if="has_schools"
					>
						<li
							class="c-event__teams-team bg-color-surface margin-4 padding-8"
							style="border: 1px solid #D9D9D9;"
							v-for="(school, index) in event.schools"
							:key="index"
						>
							<img v-lazy="$root.get_image_resizer_url(school.logo)" :alt="school.title" />
						</li>
					</ul>
					<ul
						class="c-event__teams reset-list utility-flex-center flex-wrap-wrap col-1-of-1"
						v-for="(matchup, matchupIndex) in event.matchups"
						:key="matchupIndex"
						:class="{
							'margin-b-24':
								matchupIndex === event.matchups.length - 1,
						}"
					>
						<div
							class="col-2-of-5"
							style="display: flex; align-items: center; justify-content: end; flex-wrap: wrap;"
						>
							<li
								class="c-event__teams-team bg-color-surface margin-4 padding-8"
								style="border: 1px solid #D9D9D9;"
								v-for="(school, index) in matchup.home"
								:key="index"
							>
								<img v-lazy="$root.get_image_resizer_url(school.logo)" :alt="school.title" />
							</li>
						</div>
						<div
							class="font-size-24 header-font text-safe-on-light col-1-of-5"
						>
							<span>VS</span>
						</div>
						<div
							class="col-2-of-5"
							style="display: flex; align-items: center; justify-content: start; flex-wrap: wrap;"
						>
							<li
								class="c-event__teams-team bg-color-surface margin-4 padding-8"
								style="border: 1px solid #D9D9D9;"
								v-for="(school, index) in matchup.away"
								:key="index"
							>
								<img v-lazy="$root.get_image_resizer_url(school.logo)" :alt="school.title" />
							</li>
						</div>
					</ul>
					<ul
						class="c-event__options reset-list utility-flex-center flex-wrap-wrap"
						style="margin-top: auto"
						v-show="
							event.primary_tickets_link ||
								event.ticket_options.length ||
								event.links.length
						"
					>
						<li
							class="c-event__option margin-4"
							v-if="
								event.primary_tickets_link &&
									event.primary_tickets_link !== ''
							"
						>
							<a
								class="c-event__option-btn button --on-dark"
								:href="event.primary_tickets_link"
								target="_blank"
								>Buy Tickets</a
							>
						</li>
						<li
							class="c-event__option margin-4"
							v-if="
								event.ticket_options &&
									event.ticket_options.length
							"
						>
							<button
								class="c-event__option-btn reset button --on-dark"
								:class="{ 'is-toggled': ticket_options_active }"
								@click.prevent="toggle_ticket_options"
							>
								<span>Ticket Options</span>
								<plus-icon class="margin-l-6" size=".95x" />
							</button>
						</li>
						<li
							class="c-event__option margin-4"
							v-if="event.links && event.links.length"
						>
							<button
								class="c-event__option-btn reset button --on-light"
								:class="{ 'is-toggled': other_links_active }"
								@click.prevent="toggle_other_links"
							>
								<span>Other</span>
								<plus-icon class="margin-l-6" size=".95x" />
							</button>
						</li>
					</ul>
				</div>
				<div
					class="c-event__overlay top-100 left-0 transition width-100
						bp-540:bg-color-primary bp-540:display-flex bp-540:flex-direction-column bp-540:justify-content-center bp-540:align-items-flex-start bp-540:padding-t-16 bp-540:padding-b-32 bp-540:padding-x-16 bp-540:position-absolute bp-540:top-0 bp-540:left-0 bp-540:height-100 bp-540:width-100 bp-540:z-index-2
					"
					v-if="event.ticket_options && event.ticket_options.length"
					:class="[
						{
							'position-absolute height-0 overflow-hidden bp-540:opacity-0 bp-540:pointer-events-none': !ticket_options_active,
						},
						{
							'position-static height-auto bp-540:opacity-1 bp-540:pointer-events-auto is-active': ticket_options_active,
						},
					]"
				>
					<button
						class="c-event__overlay-close margin-l-auto reset padding-16 cursor-pointer display-none
							bp-540:display-block
						"
						@click.prevent="toggle_ticket_options"
						:class="[
							{
								'opacity-0': !ticket_options_active,
							},
							{
								'opacity-1 transition': ticket_options_active,
							},
						]"
					>
						<x-icon size="1.5x" class="text-on-primary"></x-icon>
					</button>
					<ul
						class="c-event__overlay-list flex-item-1 reset-list width-100 text-align-left center_text
							bp-540:padding-x-16 bp-540:overflow-y-auto
						"
					>
						<li
							class="c-event__overlay-item border-t line-height-150 padding-12
								bp-540:border-t-none bp-540:padding-0 bp-540:margin-b-12
							"
							v-for="(ticket_option,
							index) in event.ticket_options"
							:key="index"
							:class="[
								{
									'transform-translateY-100 opacity-0': !ticket_options_active,
								},
								{
									'transform-translateY-0 opacity-1 transition': ticket_options_active,
								},
							]"
						>
							<navigation-link
								_class="c-event__overlay-link display-flex font-size-16 text-safe text-decoration-none hover:text-decoration-underline
									bp-540:text-on-primary
								"
								:url="ticket_option.link"
							>
								<chevron-right-icon
									class="transition"
									size=".9x"
								></chevron-right-icon>
								<span
									class="flex-item-1 margin-l-4"
									v-html="
										ticket_option.title.replaceAll(
											/(?:\r\n|\r|\n)/g,
											'<br />',
										)
									"
								>
									{{ ticket_option.title }}
								</span>
							</navigation-link>
						</li>
					</ul>
				</div>
				<div
					class="c-event__overlay top-100 left-0 transition width-100
						bp-540:bg-color-primary bp-540:display-flex bp-540:flex-direction-column bp-540:justify-content-center bp-540:align-items-flex-start bp-540:padding-t-16 bp-540:padding-b-32 bp-540:padding-x-16 bp-540:position-absolute bp-540:top-0 bp-540:left-0 bp-540:height-100 bp-540:width-100 bp-540:z-index-2
					"
					v-if="event.links && event.links.length"
					:class="[
						{
							'position-absolute height-0 overflow-hidden bp-540:opacity-0 bp-540:pointer-events-none': !other_links_active,
						},
						{
							'position-static height-auto bp-540:opacity-1 bp-540:pointer-events-auto is-active': other_links_active,
						},
					]"
				>
					<button
						class="c-event__overlay-close margin-l-auto reset padding-16 cursor-pointer display-none
							bp-540:display-block
						"
						@click.prevent="toggle_other_links"
						:class="[
							{
								'opacity-0': !other_links_active,
							},
							{
								'opacity-1 transition': other_links_active,
							},
						]"
					>
						<x-icon size="1.5x" class="text-on-primary"></x-icon>
					</button>
					<ul
						class="c-event__overlay-list flex-item-1 reset-list width-100 text-align-left
							bp-540:padding-x-16 bp-540:overflow-y-auto
						"
					>
						<li
							class="c-event__overlay-item border-t line-height-150 padding-12
								bp-540:border-t-none bp-540:padding-0 bp-540:margin-b-12
							"
							v-for="(link, index) in event.links"
							:key="index"
							:class="[
								{
									'transform-translateY-100 opacity-0': !other_links_active,
								},
								{
									'transform-translateY-0 opacity-1 transition': other_links_active,
								},
							]"
						>
							<a
								:href="link.link"
								class="c-event__overlay-link align-items-center display-flex font-size-16 text-safe text-decoration-none hover:text-decoration-underline
									bp-540:text-on-primary
								"
							>
								<chevron-right-icon
									class="text-on-primary transition"
									size=".9x"
								></chevron-right-icon>
								<span
									class="flex-item-1 margin-l-4"
									v-html="
										link.title.replaceAll(
											/(?:\r\n|\r|\n)/g,
											'<br />',
										)
									"
								>
									{{ link.title }}
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ChevronRightIcon, PlusIcon, XIcon } from 'vue-feather-icons';
import NavigationLink from '../common/NavigationLink';

export default {
	name: 'EventCard',

	props: {
		event: {
			required: true,
			type: Object,
			default: null,
		},
	},

	components: {
		ChevronRightIcon,
		PlusIcon,
		XIcon,
		NavigationLink,
	},

	data: () => ({
		other_links_active: false,
		ticket_options_active: false,
	}),

	methods: {
		toggle_other_links() {
			this.other_links_active = !this.other_links_active;
		},
		toggle_ticket_options() {
			this.ticket_options_active = !this.ticket_options_active;
		},
	},

	computed: {
		has_schools() {
			if (this.event && this.event.schools && this.event.schools.length) {
				return true;
			}

			return false;
		},
	},
};
</script>

<style lang="scss" scoped>
.mobile-aspect-ratio-5-2 {
	@media screen and (max-width: 540px) {
		// Workaround for iPhone (Somewhat aliased image, but better than nothing.)
		position: relative;
		padding-bottom: 40%;
		img {
			position: absolute;
			top: 0%;
			left: 0%;
			width: 100%;
			height: 100%;
			object-position: center;
			object-fit: cover;
		}
	}
}

.c-event {
	&.is-open {
		padding-bottom: 0;
	}
	&__info {
		.c-event.--no-teams & {
			flex: 0 0 100%;
		}
	}
	&__teams {
		&-team {
			border-radius: 50%;
			height: 48px;
			width: 48px;
		}
		&-image {
			border-radius: 50%;
			height: 100%;
			width: 100%;
		}
	}
	&__option-btn {
		> svg {
			transition: transform 0.25s;
		}
		&.is-toggled > svg {
			transform: rotate(-45deg);
		}
	}
	&__overlay {
		&-close {
			.c-event__overlay.is-active & {
				transition-delay: 0.15s;
			}
			flex: 0 0 auto;
			top: 16px;
			right: 16px;
		}
		&-item {
			@for $i from 1 through 25 {
				&:nth-of-type(25n + #{$i}) {
					transition-delay: $i * 0.1s;
				}
			}
		}
		&-link {
			&:hover,
			&:focus {
				> svg {
					transform: translateX(3px);
				}
			}
		}
	}
}

.center_text {
	display: flex;
	flex-direction: column;
	justify-content: center;
}

@media screen and (min-width: 1024px) {
	.c-event__info {
		padding-bottom: 10px;
	}
}

@media screen and (min-width: 540px) {
	.c-event {
		&.--no-teams {
			min-height: 350px;
		}
	}
}
</style>
