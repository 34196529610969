<template>
	<section class="s-stages" v-if="current_stages.length">
		<h2 class="accessible-hide">Stages</h2>
		<div
			class="position-relative padding-y-16 padding-x-24 transition transition-duration-25 transition-timing-function-ease
				bp-1024:padding-x-64"
			style="background-color:rgba(0,0,0,0.08);"
		>
			<div
				v-if="geoFilterEnabled || searchFilterEnabled"
				class="display-flex align-items-flex-end"
			>
				<div
					v-if="geoFilterEnabled"
					class="c-events__filter position-relative flex-item-1
						bp-1024:margin-l-auto bp-1024:flex-grow-0 bp-1024:flex-shrink-0 bp-1024:flex-basis-auto"
				>
					<select
						v-model="selected_state"
						class="c-events__select reset bg-color-surface border-radius-rounded cursor-pointer display-block padding-l-32 padding-r-64 shadow-level-1 width-100
							bp-540:padding-r-96
						"
						style="height:56px;line-height:56px;"
						name="filter-sport"
						id="filter-sport"
					>
						<option value="all">All States</option>
						<option
							v-for="state in states"
							:value="state"
							:key="state"
						>
							{{ state }}
						</option>
					</select>
					<chevron-down-icon
						class="pointer-events-none position-absolute right-0 margin-r-16 top-50 transform-translateY-n50"
						size="24"
					></chevron-down-icon>
				</div>
				<div
					v-if="searchFilterEnabled"
					class="flex-item-1
						bp-1024:flex-grow-0 bp-1024:flex-shrink-0 bp-1024:flex-basis-auto"
					:class="[
						{'margin-l-16': geoFilterEnabled},
						{'bp-1024:margin-l-auto': !geoFilterEnabled},
					]"
				>
					<input
						v-model="search_text"
						class="reset bg-color-surface border-radius-rounded padding-l-32 padding-r-48 shadow-level-1"
						style="height:56px;width:100%;"
						type="text"
						name="search"
						ref="events_search"
						placeholder="Search..."
					/>
				</div>
			</div>
		</div>
		<div
			class="component c-stage margin-y-32
				bp-1024:margin-y-0 bp-1024:border-b bp-1024:border-style-dashed"
			v-for="(stage, index) in current_stages"
			:key="index"
			:class="[
				{ 'is-active': stage.is_active },
				{
					'bp-1024:border-none':
						index === current_stages.length - 1,
				},
			]"
		>
			<header
				class="c-stage__header cursor-pointer"
				@click.prevent="toggle_stage(index)"
			>
				<div
					class="c-stage__header-main display-flex align-items-center justify-content-space-between bg-color-primary padding-x-32 padding-y-16 bp-1024:padding-0
					bp-1024:padding-y-48 bp-1024:padding-x-64"
				>
					<div
						class="c-stage__header-title display-flex align-items-center flex-item-1 bp-1024:order-1"
					>
						<!-- <svg
							class="c-stage__header-icon margin-r-8"
							width="21"
							height="21"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
						>
							<g clip-path="url(#clip0)">
								<path
									d="M14.106 6.443l.707-.707-.707.707zm3.713 0l.707-.707a1 1 0 00-1.415 0l.707.707zm-3.713-3.712l.707.707a1 1 0 000-1.414l-.707.707zM12.25.875l.707-.707a1 1 0 00-1.414 0l.707.707zM1.113 12.012l-.707-.707a1 1 0 000 1.414l.707-.707zm1.856 1.856l-.707.707a1 1 0 001.414 0l-.707-.707zm3.713 3.712l-.708-.707a1 1 0 000 1.415l.708-.708zm1.856 1.857l-.707.707a1 1 0 001.414 0l-.707-.707zM19.675 8.3l.707.707a1 1 0 000-1.414l-.707.707zm-6.276-1.15a3.625 3.625 0 005.127 0L17.11 5.737a1.625 1.625 0 01-2.298 0L13.4 7.151zm0-5.126a3.625 3.625 0 000 5.127l1.414-1.415a1.625 1.625 0 010-2.298L13.4 2.024zm-1.856-.442l1.856 1.856 1.414-1.414L12.957.168l-1.414 1.414zM1.82 12.72L12.957 1.582 11.543.168.406 11.305l1.414 1.414zm1.856.442L1.82 11.305.406 12.719l1.856 1.856 1.414-1.414zm0 1.414a1.625 1.625 0 012.298 0l1.415-1.414a3.625 3.625 0 00-5.127 0l1.414 1.414zm2.298 0a1.625 1.625 0 010 2.298l1.415 1.415a3.625 3.625 0 000-5.127l-1.415 1.414zm3.27 4.154L7.39 16.873l-1.415 1.415 1.857 1.856 1.414-1.415zm9.724-11.136L7.83 18.729l1.414 1.415L20.382 9.007l-1.415-1.414zM17.11 7.15l1.857 1.856 1.414-1.414-1.856-1.857-1.415 1.415z"
									fill="#fff"
								/>
								<path
									d="M9.625 7.875L12.1 10.35"
									stroke="#fff"
									stroke-width="2"
									stroke-linecap="round"
									stroke-linejoin="round"
								/>
							</g>
							<defs>
								<clipPath id="clip0">
									<path fill="#fff" d="M0 0h21v21H0z" />
								</clipPath>
							</defs>
						</svg> -->
						<img
							src="@/assets/tickets-v2.svg"
							width="30"
							height="30"
							alt=""
							style="filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);"
						/>
						<!-- #fff == filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(286deg) brightness(103%) contrast(103%);; -->
						<span
							class="flex-item-1 header-font font-size-30 text-on-primary text-transform-uppercase"
							>{{ stage.title }}</span
						>
					</div>
					<button
						class="c-stage__header-toggle button cursor-pointer reset bp-1024:margin-r-8 bp-1024:order-0 transition padding-x-16"
						:class="[
							{
								'bg-color-white text-muted shadow-level-1': !stage.is_active,
							},
							{
								'bg-color-primary text-on-primary border-style-solid':
									stage.is_active,
							},
						]"
						style="border-width: 2px"
					>
						<span class="padding-r-12">Ticket Options</span>
						<x-icon/>
					</button>
				</div>
			</header>
			<div
				class="c-stage__content transition"
				v-if="stage.events && stage.events.length"
				:class="[
					{ 'overflow-hidden': !stage.is_active },
					{
						'height-auto overflow-auto': stage.is_active,
					},
					{
						'bp-1024:margin-b-16': stage.is_active
					},
				]"
			>
				<championship-event-list
					class="c-stage__content-list"
					:events="stage.events"
					:state-filter="selected_state"
					:search-filter="search_text"
				/>
			</div>
		</div>
	</section>
	<section v-else class="margin-b-32"> 
	</section>
</template>

<script>
import { get_unique_values_of_property } from '@/helpers';
import { XIcon, ChevronDownIcon } from 'vue-feather-icons';
import ChampionshipEventList from '@/components/championship/ChampionshipEventList';
import moment from 'moment-timezone';

export default {
	name: 'Stages',

	components: {
		XIcon,
		ChevronDownIcon,
		ChampionshipEventList,
	},

	props: {
		stages: {
			required: true,
			type: Array,
			default: () => [],
		},
		geoFilterEnabled: {
			required: true,
			type: Boolean,
			default: () => true
		},
		searchFilterEnabled: {
			required: true,
			type: Boolean,
			default: () => true
		},
	},

	data: () => ({
		selected_state: 'all',
		search_text: '',
		search_toggled: false,
		filters_active: false,
	}),

	computed: {
		states() {
			let all_events = [];
			this.current_stages.forEach(stage => {
				all_events = all_events.concat(stage.events);
			});
			return get_unique_values_of_property(all_events, 'state').sort();
		},

		current_stages() {
			return this.stages.filter(stage => {
				if (stage.end_date) {
					let expirationDate;
					if(stage.end_date_time) {
						// Convert from Eastern time to local time.
						expirationDate = moment.tz(stage.end_date + " " + stage.end_date_time, "YYYY-MM-DD HH:mm", 'America/New_York').toDate();
					}
					else {
						// Convert from Eastern time to local time.
						expirationDate = moment.tz(stage.end_date, "YYYY-MM-DD", 'America/New_York').toDate();
					}

					return new Date() < expirationDate;
				}
				return true;
			});
		},
	},

	methods: {
		toggle_filters() {
			this.filters_active = !this.filters_active;
		},
		toggle_stage(index) {
			let _stage = this.current_stages[index];
			if (!_stage) return;
			_stage.is_active = !_stage.is_active;
		},
		clear_search() {
			this.search_text = '';
		},
		open_search() {
			this.search_toggled = true;
			this.$refs.events_search.focus();
		},
		close_search() {
			this.search_toggled = false;
			this.clear_search();
		},
		toggle_search() {
			if (this.search_toggled === true) this.close_search();
			else this.open_search();
		},
	},
};
</script>

<style lang="scss" scoped>
.c-stage {
	&:not(.is-active) {
		.c-stage__content {
			height: 0px !important;
		}
	}
	&__header-toggle {
		max-width: 110px;
		margin-left: 5px;
		@media screen and (min-width: 1024px) {
			max-width: none;
			margin-left: 0;
		}
	}
	&__header-toggle > svg {
		transition: transform 0.25s;
		.c-stage:not(.is-active) & {
			transform: rotate(45deg);
		}
	}
	&__header-toggle > span {
		max-width: 60px;
		@media screen and (min-width: 1024px) {
			max-width: none;
		}
	}
}
@media screen and (min-width: 1024px) {
	.c-stage {
		// &__header {
		// 	&-icon path {
		// 		fill: var(--color-primary);
		// 	}
		// }
		&__content-list {
			padding-left: 60px !important;
			padding-right: 60px !important;
		}
	}
}
</style>
