<template>
	<div
		class="component c-championship c-championship-new--card position-relative height-100 bg-color-white margin-y-10 bp-768:margin-y-0"
		:class="[{ 'is-open': other_links_active }]"
		v-if="data"
	>
		<div
			class="c-championship__url display-block text-decoration-none display-flex flex-direction-column position-static height-100"
		>
			<div class="c-championship__wrapper">
				<div
					:class="
						'c-championship__media bg-color-light-background overflow-hidden' +
							(has_link ? ' has-link' : '')
					"
					@click="on_image_click"
				>
					<img
						v-lazy="$root.get_image_resizer_url(`https://d230gea5qwdu36.cloudfront.net/${data.primary_image.images[0].image}`)"
						:alt="data.primary_image.images[0].alt"
						class="c-championship__image height-100 object-fit-cover object-position-c transition width-100 top-0"
						v-if="
							data.primary_image &&
								data.primary_image.images.length
						"
					/>
					<img
						class="c-championship__image height-100 object-fit-cover object-position-c transition width-100 top-0"
						src="@/assets/champ_info_img.webp"
						alt=""
						v-else
					/>
				</div>
				<div
					class="c-championship__details padding-16 margin-b-auto padding-32"
				>
					<div
						class="c-championship__title display-flex flex-direction-column"
					>
						<div
							class="flex-item-1 header-font font-size-18 line-height-100 text-transform-uppercase transition transition-timing-function-ease
							bp-1024:font-size-30"
						>
							{{ data.title }}
						</div>
					</div>
				</div>
			</div>
			<div
				class="c-championship__more text-brand text-transform-uppercase header-font margin-t-auto padding-b-32 padding-x-32 hover:text-info"
			>
				<div>
					<navigation-link
						v-for="link in data.links"
						:url="link.link_url"
						:key="link.link_id"
						_class="c-championship__more--option button text-white margin-r-4 margin-y-4 bg-color-primary hover:bg-color-secondary"
					>
						{{ link.link_text }}
					</navigation-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { ChevronRightIcon } from 'vue-feather-icons';
import NavigationLink from '@/components/common/NavigationLink';

export default {
	name: 'NewsListCard',
	components: {
		NavigationLink,
	},
	props: {
		data: {
			required: true,
			type: Object,
			default: null,
		},
	},
	data() {
		return {
			other_links_active: false,
		};
	},
	computed: {
		has_link() {
			return this.data?.links?.length > 0;
		},
	},
	methods: {
		toggle_other_links() {
			this.other_links_active = !this.other_links_active;
		},
		primary_link(primary_link) {
			if (primary_link) {
				return primary_link;
			}

			return '';
		},
		on_image_click() {
			// Navigate to first link
			if (this.has_link)
				window.open(this.data.links[0].link_url, '_blank');
		},
	},
};
</script>

<style lang="scss">
.c-championship-new--card {
	box-shadow: 0 6px 8px -2px rgba(0, 0, 0, 0.4);
	min-height: 400px;
	&__wrapper {
		min-height: 350px;
	}
	.c-championship {
		&.is-open {
			padding-bottom: 0;
		}
		//for naming
		&__url {
			&:hover,
			&:focus {
				.c-championship__image {
					transform: scale(1.1);
				}

				.c-championship__title > span {
					color: var(--color-primary-hover);
				}
			}
		}
		&__title {
			color: var(--color-primary);
			align-items: center;
			text-align: center;
		}
		&__description {
			color: #7c7e81;
			font-family: 'Roboto', Arial, sans-serif;
		}
		&__more {
			display: flex;
			justify-content: center;
			&--option {
				text-decoration: none;
				> svg {
					transition: transform 0.25s;
				}
				&.is-toggled > svg {
					transform: rotate(90deg);
				}
			}
		}
		&__media {
			&.has-link {
				cursor: pointer;
			}
		}
	}
	&__overlay {
		&-close {
			.c-event__overlay.is-active & {
				transition-delay: 0.15s;
			}
			flex: 0 0 auto;
			top: 16px;
			right: 16px;
		}
		&-item {
			@for $i from 1 through 25 {
				&:nth-of-type(25n + #{$i}) {
					transition-delay: $i * 0.1s;
				}
			}
		}
		&-link {
			&:hover,
			&:focus {
				> svg {
					transform: translateX(3px);
				}
			}
		}
	}
}
</style>
