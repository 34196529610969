var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.events.length)?_c('div',{staticClass:"component c-events c-events--list"},[_c('transition-group',{staticClass:"c-events__list reset-list row \n\t\t\tbp-540:padding-t-24",attrs:{"name":"list","tag":"ul"}},_vm._l((_vm.filtered_events),function(event,index){return _c('li',{key:event.id,staticClass:"c-events__card transition bp-540:padding-4",class:[
				{
					'col-1-of-1 bp-768:col-1-of-2 bp-1280:col-1-of-3 bp-1440:col-1-of-4':
						event.schools && event.schools.length,
				},
				{
					'col-1-of-1 bp-768:col-1-of-2 bp-1280:col-1-of-3 bp-1440:col-1-of-4':
						event.schools && !event.schools.length,
				},
				{
					'padding-b-16': index < _vm.events.length - 1,
				} ]},[_c('championship-event-card',{attrs:{"event":event}})],1)}),0)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }