<template>
	<div
		class="component c-championships c-championships-news--list"
		:class="isMobile ? 'c-championships-news--mobileBackground' : ''"
	>
		<loading-indicator v-if="loading" />
		<transition-group
			name="list"
			tag="ul"
			class="c-championships-news__list reset-list row"
			v-if="championshipNews && championshipNews.length"
		>
			<li
				class="c-championships-news__item col-1-of-1 bp-540:col-1-of-2 margin-y-4 bp-540:padding-4 bp-1024:col-1-of-3 bp-1440:col-1-of-4"
				v-for="item in championshipNews"
				:key="item.id"
			>
				<component :is="componentToRender" :data="item" />
			</li>
		</transition-group>
		<div
			class="padding-x-32 padding-b-32 padding-t-16 bp-1024:padding-b-24"
			v-else
		>
			There are currently no championship events at this moment.
		</div>
	</div>
</template>

<script>
import { get, sort_by } from '@/helpers';
import NewsListCard from '@/components/championships/templates/NewsListCard';
import NewsListCardMobile from '@/components/championships/templates/NewsListCardMobile';
import LoadingIndicator from '@/components/common/LoadingIndicator';

export default {
	name: 'NewsList',
	components: {
		NewsListCard,
		NewsListCardMobile,
		LoadingIndicator,
	},
	props: {
		route_name: {
			type: String,
			default: null,
		},
		slideshowId: {
			type: Number,
			default: null,
			required: false,
		},
	},
	computed: {
		isMobile() {
			return this.windowWidth < 539;
		},
		componentToRender() {
			if (this.isMobile) return 'NewsListCardMobile';
			else return 'NewsListCard';
		},
	},
	data() {
		return {
			championshipNews: [],
			loading: false,
			windowWidth: window.innerWidth,
		};
	},
	methods: {
		get_data() {
			this.loading = true;
			const request_options = {
				type: 'slideshow',
				count: 5,
				slideshow_id: this.slideshowId,
			};

			get(
				`${this.$root.proxy}/services/adaptive_components.ashx`,
				request_options,
			)
				.then(response => {
					this.championshipNews = response;
				})
				.catch(error => {
					this.loading = false;
					console.log(error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		onWindowResize() {
			this.windowWidth = window.innerWidth;
		},
	},
	created() {
		if (this.slideshowId) {
			this.get_data();
		}
	},
	mounted() {
		this.$nextTick(() => {
			window.addEventListener('resize', this.onWindowResize);
		});
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onWindowResize);
	},
};
</script>

<style lang="scss">
.c-championships-news {
	&--mobileBackground {
		background-color: #e5e5e5;
		padding-top: 4px;
	}
}
</style>
