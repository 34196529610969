var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.event)?_c('div',{staticClass:"component c-event bg-color-surface position-relative height-100 shadow-level-1",class:[
		{ '--no-teams': !_vm.has_schools },
		{ 'is-open': _vm.other_links_active || _vm.ticket_options_active } ]},[_c('div',{staticClass:"top-0 left-0 height-100 width-100",class:[
			{
				'bp-540:display-flex justify-content-center align-items-center': !_vm.has_schools,
			},
			{
				'position-static':
					_vm.other_links_active || _vm.ticket_options_active,
			} ]},[_c('div',{staticClass:"c-event__info display-flex flex-direction-column justify-content-center overflow-hidden position-relative text-align-center height-100\n\t\t\t\tbp-1024:justify-content-flex-start",class:{ 'height-100': _vm.has_schools }},[_c('div',{staticClass:"flex-grow-0 flex-shrink-0 mobile-aspect-ratio-5-2"},[(_vm.event.image && _vm.event.image.url)?_c('img',{staticClass:"bg-color-surface-alt object-fit-cover\n\t\t\t\t\t\tbp-540:width-100 bp-540:height-100 bp-540:object-fit-contain",attrs:{"src":_vm.$root.get_image_resizer_url(("" + (_vm.$root.proxy) + (_vm.event.image.url))),"alt":_vm.event.image.alt}}):_vm._e()]),_c('div',{staticClass:"padding-y-32 padding-x-16 bp-1024:padding-x-32 display-flex flex-direction-column align-items-center height-100"},[_c('div',{staticClass:"c-event__title font-size-24 header-font text-safe-on-light text-transform-uppercase"},[_c('chevron-right-icon',{staticClass:"text-on-primary transition",attrs:{"size":".9x"}}),_c('span',{domProps:{"innerHTML":_vm._s(
							_vm.event.title.replaceAll(
								/(?:\r\n|\r|\n)/g,
								'<br />'
							)
						)}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.event.start_date),expression:"event.start_date"}],staticClass:"c-event__date font-size-14 font-weight-700 margin-t-8 text-brand text-transform-uppercase"},[(_vm.event.start_date)?_c('span',{staticClass:"c-event__date-start"},[_vm._v(_vm._s(_vm._f("luxon")(_vm.event.start_date)))]):_vm._e(),(
							_vm.event.end_date &&
								_vm.event.end_date !== _vm.event.start_date
						)?_c('span',{staticClass:"margin-x-4"},[_vm._v("-")]):_vm._e(),(
							_vm.event.end_date &&
								_vm.event.end_date !== _vm.event.start_date
						)?_c('span',{staticClass:"c-event__date-end"},[_vm._v(_vm._s(_vm._f("luxon")(_vm.event.end_date)))]):_vm._e()]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.event.city || _vm.event.state || _vm.event.facility),expression:"event.city || event.state || event.facility"}],staticClass:"c-event__locale font-size-14 margin-t-8 margin-b-24"},[_c('span',{staticClass:"c-event__locale-location font-weight-700 text-safe-on-light"},[_vm._v(" "+_vm._s(_vm.event.city)+" "+_vm._s(_vm.event.city && _vm.event.state ? ', ' : '')+" "+_vm._s(_vm.event.state)+" ")]),(_vm.event.facility && _vm.event.facility !== '')?_c('span',{staticClass:"text-muted margin-x-4"},[_vm._v("|")]):_vm._e(),_c('span',{staticClass:"c-event__locale-stadium text-muted"},[_vm._v(_vm._s(_vm.event.facility))])]),(_vm.has_schools)?_c('ul',{staticClass:"c-event__teams margin-b-24 reset-list utility-flex-center flex-wrap-wrap"},_vm._l((_vm.event.schools),function(school,index){return _c('li',{key:index,staticClass:"c-event__teams-team bg-color-surface margin-4 padding-8",staticStyle:{"border":"1px solid #D9D9D9"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$root.get_image_resizer_url(school.logo)),expression:"$root.get_image_resizer_url(school.logo)"}],attrs:{"alt":school.title}})])}),0):_vm._e(),_vm._l((_vm.event.matchups),function(matchup,matchupIndex){return _c('ul',{key:matchupIndex,staticClass:"c-event__teams reset-list utility-flex-center flex-wrap-wrap col-1-of-1",class:{
						'margin-b-24':
							matchupIndex === _vm.event.matchups.length - 1,
					}},[_c('div',{staticClass:"col-2-of-5",staticStyle:{"display":"flex","align-items":"center","justify-content":"end","flex-wrap":"wrap"}},_vm._l((matchup.home),function(school,index){return _c('li',{key:index,staticClass:"c-event__teams-team bg-color-surface margin-4 padding-8",staticStyle:{"border":"1px solid #D9D9D9"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$root.get_image_resizer_url(school.logo)),expression:"$root.get_image_resizer_url(school.logo)"}],attrs:{"alt":school.title}})])}),0),_vm._m(0,true),_c('div',{staticClass:"col-2-of-5",staticStyle:{"display":"flex","align-items":"center","justify-content":"start","flex-wrap":"wrap"}},_vm._l((matchup.away),function(school,index){return _c('li',{key:index,staticClass:"c-event__teams-team bg-color-surface margin-4 padding-8",staticStyle:{"border":"1px solid #D9D9D9"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.$root.get_image_resizer_url(school.logo)),expression:"$root.get_image_resizer_url(school.logo)"}],attrs:{"alt":school.title}})])}),0)])}),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(
						_vm.event.primary_tickets_link ||
							_vm.event.ticket_options.length ||
							_vm.event.links.length
					),expression:"\n\t\t\t\t\t\tevent.primary_tickets_link ||\n\t\t\t\t\t\t\tevent.ticket_options.length ||\n\t\t\t\t\t\t\tevent.links.length\n\t\t\t\t\t"}],staticClass:"c-event__options reset-list utility-flex-center flex-wrap-wrap",staticStyle:{"margin-top":"auto"}},[(
							_vm.event.primary_tickets_link &&
								_vm.event.primary_tickets_link !== ''
						)?_c('li',{staticClass:"c-event__option margin-4"},[_c('a',{staticClass:"c-event__option-btn button --on-dark",attrs:{"href":_vm.event.primary_tickets_link,"target":"_blank"}},[_vm._v("Buy Tickets")])]):_vm._e(),(
							_vm.event.ticket_options &&
								_vm.event.ticket_options.length
						)?_c('li',{staticClass:"c-event__option margin-4"},[_c('button',{staticClass:"c-event__option-btn reset button --on-dark",class:{ 'is-toggled': _vm.ticket_options_active },on:{"click":function($event){$event.preventDefault();return _vm.toggle_ticket_options($event)}}},[_c('span',[_vm._v("Ticket Options")]),_c('plus-icon',{staticClass:"margin-l-6",attrs:{"size":".95x"}})],1)]):_vm._e(),(_vm.event.links && _vm.event.links.length)?_c('li',{staticClass:"c-event__option margin-4"},[_c('button',{staticClass:"c-event__option-btn reset button --on-light",class:{ 'is-toggled': _vm.other_links_active },on:{"click":function($event){$event.preventDefault();return _vm.toggle_other_links($event)}}},[_c('span',[_vm._v("Other")]),_c('plus-icon',{staticClass:"margin-l-6",attrs:{"size":".95x"}})],1)]):_vm._e()])],2),(_vm.event.ticket_options && _vm.event.ticket_options.length)?_c('div',{staticClass:"c-event__overlay top-100 left-0 transition width-100\n\t\t\t\t\tbp-540:bg-color-primary bp-540:display-flex bp-540:flex-direction-column bp-540:justify-content-center bp-540:align-items-flex-start bp-540:padding-t-16 bp-540:padding-b-32 bp-540:padding-x-16 bp-540:position-absolute bp-540:top-0 bp-540:left-0 bp-540:height-100 bp-540:width-100 bp-540:z-index-2\n\t\t\t\t",class:[
					{
						'position-absolute height-0 overflow-hidden bp-540:opacity-0 bp-540:pointer-events-none': !_vm.ticket_options_active,
					},
					{
						'position-static height-auto bp-540:opacity-1 bp-540:pointer-events-auto is-active': _vm.ticket_options_active,
					} ]},[_c('button',{staticClass:"c-event__overlay-close margin-l-auto reset padding-16 cursor-pointer display-none\n\t\t\t\t\t\tbp-540:display-block\n\t\t\t\t\t",class:[
						{
							'opacity-0': !_vm.ticket_options_active,
						},
						{
							'opacity-1 transition': _vm.ticket_options_active,
						} ],on:{"click":function($event){$event.preventDefault();return _vm.toggle_ticket_options($event)}}},[_c('x-icon',{staticClass:"text-on-primary",attrs:{"size":"1.5x"}})],1),_c('ul',{staticClass:"c-event__overlay-list flex-item-1 reset-list width-100 text-align-left center_text\n\t\t\t\t\t\tbp-540:padding-x-16 bp-540:overflow-y-auto\n\t\t\t\t\t"},_vm._l((_vm.event.ticket_options),function(ticket_option,index){return _c('li',{key:index,staticClass:"c-event__overlay-item border-t line-height-150 padding-12\n\t\t\t\t\t\t\tbp-540:border-t-none bp-540:padding-0 bp-540:margin-b-12\n\t\t\t\t\t\t",class:[
							{
								'transform-translateY-100 opacity-0': !_vm.ticket_options_active,
							},
							{
								'transform-translateY-0 opacity-1 transition': _vm.ticket_options_active,
							} ]},[_c('navigation-link',{attrs:{"_class":"c-event__overlay-link display-flex font-size-16 text-safe text-decoration-none hover:text-decoration-underline\n\t\t\t\t\t\t\t\tbp-540:text-on-primary\n\t\t\t\t\t\t\t","url":ticket_option.link}},[_c('chevron-right-icon',{staticClass:"transition",attrs:{"size":".9x"}}),_c('span',{staticClass:"flex-item-1 margin-l-4",domProps:{"innerHTML":_vm._s(
									ticket_option.title.replaceAll(
										/(?:\r\n|\r|\n)/g,
										'<br />'
									)
								)}},[_vm._v(" "+_vm._s(ticket_option.title)+" ")])],1)],1)}),0)]):_vm._e(),(_vm.event.links && _vm.event.links.length)?_c('div',{staticClass:"c-event__overlay top-100 left-0 transition width-100\n\t\t\t\t\tbp-540:bg-color-primary bp-540:display-flex bp-540:flex-direction-column bp-540:justify-content-center bp-540:align-items-flex-start bp-540:padding-t-16 bp-540:padding-b-32 bp-540:padding-x-16 bp-540:position-absolute bp-540:top-0 bp-540:left-0 bp-540:height-100 bp-540:width-100 bp-540:z-index-2\n\t\t\t\t",class:[
					{
						'position-absolute height-0 overflow-hidden bp-540:opacity-0 bp-540:pointer-events-none': !_vm.other_links_active,
					},
					{
						'position-static height-auto bp-540:opacity-1 bp-540:pointer-events-auto is-active': _vm.other_links_active,
					} ]},[_c('button',{staticClass:"c-event__overlay-close margin-l-auto reset padding-16 cursor-pointer display-none\n\t\t\t\t\t\tbp-540:display-block\n\t\t\t\t\t",class:[
						{
							'opacity-0': !_vm.other_links_active,
						},
						{
							'opacity-1 transition': _vm.other_links_active,
						} ],on:{"click":function($event){$event.preventDefault();return _vm.toggle_other_links($event)}}},[_c('x-icon',{staticClass:"text-on-primary",attrs:{"size":"1.5x"}})],1),_c('ul',{staticClass:"c-event__overlay-list flex-item-1 reset-list width-100 text-align-left\n\t\t\t\t\t\tbp-540:padding-x-16 bp-540:overflow-y-auto\n\t\t\t\t\t"},_vm._l((_vm.event.links),function(link,index){return _c('li',{key:index,staticClass:"c-event__overlay-item border-t line-height-150 padding-12\n\t\t\t\t\t\t\tbp-540:border-t-none bp-540:padding-0 bp-540:margin-b-12\n\t\t\t\t\t\t",class:[
							{
								'transform-translateY-100 opacity-0': !_vm.other_links_active,
							},
							{
								'transform-translateY-0 opacity-1 transition': _vm.other_links_active,
							} ]},[_c('a',{staticClass:"c-event__overlay-link align-items-center display-flex font-size-16 text-safe text-decoration-none hover:text-decoration-underline\n\t\t\t\t\t\t\t\tbp-540:text-on-primary\n\t\t\t\t\t\t\t",attrs:{"href":link.link}},[_c('chevron-right-icon',{staticClass:"text-on-primary transition",attrs:{"size":".9x"}}),_c('span',{staticClass:"flex-item-1 margin-l-4",domProps:{"innerHTML":_vm._s(
									link.title.replaceAll(
										/(?:\r\n|\r|\n)/g,
										'<br />'
									)
								)}},[_vm._v(" "+_vm._s(link.title)+" ")])],1)])}),0)]):_vm._e()])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"font-size-24 header-font text-safe-on-light col-1-of-5"},[_c('span',[_vm._v("VS")])])}]

export { render, staticRenderFns }